<template>
  <section class="NotAllowed">
    <div class="NotAllowed-Content">
      <img class="NotAllowed-Image" src="@/assets/img/joker_sad.png" alt="Page not found" />
      <h1 class="Title Title--type-h1 NotAllowed-Title">
        Oops
      </h1>
      <div class="TextMain NotAllowed-Text">
        Access denied. You don't have permission to access this page.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotAllowed',
};
</script>

<style lang="scss">
.NotAllowed {

  &-Title {
    margin-bottom: 12px;
    line-height: 1.27;
    text-align: center;
    color: var(--color-text-main);

    @media (min-width: $screen-s) {
      width: 559px / $screen-xl * 100%;
      margin-bottom: 17px;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 32px;

    @media (min-width: $screen-s) {
      padding-top: 120px;
      background-image: url(../assets/img/notfound_bg.png);
      background-repeat: no-repeat;
      background-position: center 70px;
      background-size: contain;
    }
  }

  &-Image {
    width: 210px;

    @media (min-width: $screen-s) {
      width: auto;
    }
  }

  &-Text {
    text-align: center;

    @media (min-width: $screen-s) {
      width: 559px / $screen-xl * 100%;
      margin-bottom: 44px;
    }
  }

  &-Btn {
    width: 100%;
    margin-top: 38px;

    @media (min-width: $screen-s) {
      width: auto;
    }
  }
}
</style>
